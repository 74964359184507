<template>
  <div id="msgEditor">
    <v-card flat :color="this.$headerColor">
      <v-row>

        <v-col align="center">
          <h3 class="display-1 font-weight-bold mb-3">Edit eMail Messages</h3>
        </v-col>
        <v-tabs background-color="brown" color="yellow " class="tab-font" dark centered show-arrows center-active
          model="content" slider-size="8">
          <v-tab key="/msgEditor/newYear.msg/New Year Message" to="/msgEditor/newYear.msg/New Year Message/"
            :disabled="disableTab" @click="getMsgFile('newYear.msg')">
            New Year Message
          </v-tab>
          <v-tab key="/msgEditor/allRides.msg/All Rides Message/" to="/msgEditor/allRides.msg/All Rides Message/"
            :disabled="disableTab" @click="getMsgFile('allRides.msg')">
            All Rides Message
          </v-tab>
          <v-tab key="msgEditor/pendingRides.msg/Pending Rides Message"
            to="/msgEditor/pendingRides.msg/Pending Rides Message/" :disabled="disableTab"
            @click="getMsgFile('pendingRides.msg')">
            Pending Rides Message
          </v-tab>
          <v-tab key="/msgEditor/changesPending.msg/Changes Pending"
            to="/msgEditor/changesPending.msg/Changes Pending Message/" :disabled="disableTab"
            @click="getMsgFile('changesPending.msg')">
            Changes Pending Message
          </v-tab>
          <v-tab key="/msgEditor/monthBefore.msg/Ride Reminder" to="/msgEditor/monthBefore.msg/Ride Reminder/"
            :disabled="disableTab" @click="getMsgFile('monthBefore.msg')">
            Ride Reminder Message
          </v-tab>
          <v-tab key="/msgEditor/rideLeaderNeeded.msg/Ride Leader Needed"
            to="/msgEditor/rideLeaderNeeded.msg/Ride Leader Needed/" :disabled="disableTab"
            @click="getMsgFile('rideLeaderNeeded.msg')">
            Ride Leader Needed Message
          </v-tab>
        </v-tabs>
        <!--           <v-btn value="save" color="primary" @click="saveMsg('save')">
            Save
          </v-btn>
 -->
      </v-row> </v-card>
    <v-container fluid :class="this.$bgColor" class="browser">
      <v-row>
        <v-col cols="1">
          <v-card flat :color="this.$bgColor" height="50"></v-card>
          <Tip text="This will send a sample of this message to the RidesChair email address. <br />">
            <slot>
              <v-btn value="test" class="btn" color="primary" :disabled="disableTab" @click="saveMsg('test')">
                Test
              </v-btn>
            </slot>
          </Tip>
          <Tip
            text="Saves current version. When you press Revert, It will restores the version rom when you last pressedsaved. ">
            <slot>
              <v-btn class="btn" color="green white--text" :disabled="disableTab" @click="saveMsg('install')">
                Save
              </v-btn>
            </slot>
          </Tip>
          <Tip text="Go back to the last saved version  of this message">
            <slot>
              <v-btn class="btn" color="red white--text" :disabled="disableTab" @click="saveMsg('revert')">
                Revert
              </v-btn>
            </slot>
          </Tip>
        </v-col>
        <v-col>
          <v-row>
            <v-card :color="this.$bgColor">
              <h3>
                {{ $route.params.title }}
              </h3>
              <Tip color="green" text="When creating an email
            following substitution variables are available: <br />  <br />
            @RideLeader &emsp;  will be replaced with the Ride Leader's Name.<br />
            @RideYear &emsp;  will be replaced with current Calendar year.<br />
             @PrevYear &emsp;  will be replaced with previous Calendar year.<br />
            @RideList &emsp;  will be replaced with the list of rides <br />
            @RideButton &emsp;  will be place a button that will
            go to the Ride Leader Review page. <br />
            @CalendarButton &emsp; will place with a button that loads the Calendar with ony the Ride Leaders rides.
             <br /> 
            <br />
            Some, but not all text formatting commands will work on these variables (size,color, font all work).">
                <h5>Substitutions Variables </h5>
              </Tip>

              <!-- 
        This is tinymce
        APIkey: uc46vz0xq4tugh14zeq8f2f8pap1dqrb2p7l5azjmluczknf
        Website: https://www.tiny.cloud/
        Logon: ebcrides@ebcrides.org
        Password: ebc55555cal
        registered domains: ebcrides.org, localhost
       -->

              <!--                             <div style="display: none">
                                {{ (inp = content) }}
                            </div>
                                        oninit: getMsgFile(
                                            this.$route.params.filename
                                        ),
 -->

              <template>
                <editor v-model="content" api-key="uc46vz0xq4tugh14zeq8f2f8pap1dqrb2p7l5azjmluczknf" :init="{
                  body_class: 'Editor',
                  backcolor: 'red',
                  plugins:
                    ' paste importcss searchreplace autolink  directionality code  charmap image link media   table charmap hr  anchor  advlist lists    quickbars emoticons',
                  menubar: ' edit  insert format tools table',
                  toolbar:
                    'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | insertfile image media template link anchor  | ltr rtl',
                  importcss_append: true,
                  height: 600,
                  image_caption: true,
                  toolbar_mode: 'sliding',
                  contextmenu: 'image',
                  body_class: 'my_class',
                }">
                  {{ content }}
                </editor>
              </template>
              <!--           <v-btn value="save" color="primary" @click="saveMsg('save')">
            Save
          </v-btn>
 -->
            </v-card>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
// import Editor from "../../../tinymce/node_modules/@tinymce/tinymce-vue";
import Editor from "@tinymce/tinymce-vue";
import axios from "axios";
import Tip from "../components/Tip";
import mixins from "../components/mixins";
import EventBus from "@/event-bus";
export default {
  name: "MsgEditor",
  components: {
    Editor,
    Tip,
  },
  mixins: [mixins],
  data() {
    return {
      //          showPopUp: false,
      //      calendar: "ksbmqapicnorxb63e1",
      editor: Editor,
      disableTab: false,
      headerColor: "green",
      content: "New Year",
      //    filename: "no",
      disabled: null,
      // title: "Nodda",
    };
  },

  async mounted() {
    await this.getCalData().then(() => {
      EventBus.$emit("wait", true);

      this.getMsgFile(this.$route.params.filename);
      EventBus.$emit("wait", false);
    });
  },
  methods: {
    getMsgFile(filename) {

      EventBus.$emit("wait", true);
      var url = this.servers["python"] + "getfile?msg=" + filename;
      axios({
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
        url: url,
      })
        .then((results) => {
          //          this.sleep(4000).then(() => {});
          this.content = results.data;

          //        alert(this.rideLeaderList);
          EventBus.$emit("wait", false);
        })
        .catch((error) => {
          this.handleError("Getting Message File", error, url);
          EventBus.$emit("wait", false);
        });
    },

    saveMsg(action) {
      var url = "";
      var rideStatus = "all";
      const year = this.getYear("current");
      this.disableTab = true;
      if (action === "test") {
        if (this.$route.params.filename.search("pending") > -1)
          rideStatus = this.getStatusID("pend");
        if (this.$route.params.filename.search("changes") > -1)
          rideStatus = this.getStatusID("change");
        url =
          this.servers["python"] +
          "saveMsg?msgID=" +
          action +
          "&cal=" +
          this.$noPasswordCal +
          // this.$calendar +
          "&rideStatus=" +
          rideStatus +
          "&test=True" +
          "&id=" +
          year +
          "&hostName=" +
          location.host +
          "&filename=" +
          this.$route.params.filename +
          "&msg=" +
          encodeURIComponent(this.content);
        // alert("fname:" + url);
      } else {
        url =
          this.servers["python"] +
          "saveMsg?msgID=" +
          action +
          "&cal=" +
          this.$noPasswordCal +
          // this.$calendar +
          "&rideStatus=" +
          rideStatus +
          "&filename=" +
          this.$route.params.filename +
          "&id=" +
          year +
          "&hostName=" +
          location.host +
          "&msg=" +
          encodeURIComponent(this.content);
      }
      axios({
        method: "GET",
        url: url,
      })
        .then((response) => {
          if (action === "revert") {
            this.content = response.data;
            //            this.getit(this.$route.params.filename);
          }
          this.disableTab = false;
        })
        .catch((error) => {
          this.handleError("At Save Message", error, url);
          //          disable = false;
          this.disableTab = false;
        });
    },
  },
};
</script>
<style scoped>
.tab-font {
  color: red;

  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

}

.btn {
  margin-right: -15px;
  margin-bottom: 10px;
}

.browser {
  margin-top: 14px;
}
</style>
