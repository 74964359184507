import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import store from "./store";
import VueClipboard from "vue-clipboard2";
import EventBus from "./event-bus";
VueClipboard.config.autoSetContainer = true; // add this line
Vue.use(VueClipboard); // const server = "http://192.168.1.22/";

Vue.config.errorHandler = (err, vm, info) => {
  // vm: component in which error occured
  // info: Vue specific error information such as lifecycle hooks, events etc.

  //  x[2] = {
  //    agreeButtonText: "Done",
  //    oneButton: true,
  //  };
  EventBus.$emit("error", [err, info]);
  // alert(err, info);
  EventBus.$emit("wait", false);

  vm;
  return false;
};
// TODO: Perform any custom logic or log to server

new Vue({
  router,
  vuetify,
  store,
  render: (h) => h(App),
}).$mount("#app");
