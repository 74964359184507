import Vue from "vue";
const theme = "spring";

if (theme === "original") {
  Vue.prototype.$menuColor = "red darken - 1";
  Vue.prototype.$waitColor = "deep orange";
  Vue.prototype.$fgColor = "orange lighten - 2 bodyFont";
  Vue.prototype.$bgColor = "orange lighten - 3";
  Vue.prototype.$headerColor = "deep orange ligten-2 headerFont";
} else if (theme === "blue") {
  Vue.prototype.$menuColor = "primary darken-1";
  Vue.prototype.$waitColor = "primary darken-2";
  Vue.prototype.$headerColor = "primary lighten-2 headerFont";
  Vue.prototype.$bgColor = "primary lighten-4";
  Vue.prototype.$fgColor = "primary lighten-3";
  Vue.prototype.$buttonColor = "primary";
} else if (theme === "spring") {
  Vue.prototype.$waitColor = "earth yellow darken-2";
  Vue.prototype.$menuColor = "bud green darken-4";
  Vue.prototype.$headerColor = "arctic lime lighten-2";
  Vue.prototype.$bgColor = "green lighten-4"; //
  Vue.prototype.$fgColor = "Android green lighten-2";
  Vue.prototype.$buttonColor = "dark green pastel lighten-2";
}

/*
Vue.prototype.$statusColors = {
  confirmColor: "#00C853",
  changeColor: "deep purple white--text",
  pendColor: "yellow lighten - 2 black--text ",
  neededColor: "#8150a0a",
  cancelColor: "pink lighten-2 black--text ",
};
*/

Vue.prototype.$colors = [
  { id: "26", color: "#5a8121" },
  { id: "18", color: "#4770d8" },
  { id: "3", color: "#553711" },
  { id: "20", color: "#133897" },
  { id: "34", color: "#724f22" },
  { id: "19", color: "#2951b9" },
  { id: "35", color: "#9c6013" },
  { id: "2", color: " #cf2424" },
  { id: "34", color: "#724f22" },
  { id: "19", color: "#2951b9" },
  { id: "35", color: "#9c6013" },
  { id: "18", color: "#4770d8" },
  { id: "5", color: "#ca76<09" },
  { id: "17", color: "#668CB3" },
  { id: "6", color: "#f16c20" },
  { id: "21", color: "#1a5173" },
  { id: "7", color: "#f58a4b" },
  { id: "22", color: "#1a699c" },
  { id: "8", color: "#d2b53b" },
  { id: "23", color: "#0080a6" },
  { id: "36", color: "#f6c811" },
  { id: "24", color: "#4aaace" },
  { id: "3", color: "#a01a1a" },
  { id: "28", color: "#176413" },
  { id: "37", color: "#ffffff" },
  { id: "27", color: "#2d850e" },
  { id: "1", color: "#ce1212" },
  { id: "25", color: "#88b347" },
  { id: "38", color: "#b20d47" },
  { id: "29", color: "#0f4c30" },
  { id: "39", color: "#d8135a" },
  { id: "30", color: "#386651" },
  { id: "40", color: "#e81f78" },
  { id: "31", color: "#00855b" },
  { id: "41", color: "#f5699a" },
  { id: "32", color: "#4fb5a1" },
  { id: "12", color: "#7a0f60" },
  { id: "42", color: "#5c1c1c" },
  { id: "11", color: "#9d3283" },
  { id: "4", color: "#7e3838" },
  { id: "10", color: "#b84e9d" },
  { id: "43", color: "#a55757" },
  { id: "9", color: "#d96fbf" },
  { id: "44", color: "#c37070" },
  { id: "13", color: "#542382" },
  { id: "45", color: "#000000" },
  { id: "14", color: "#7742a9" },
  { id: "46", color: "#383838" },
  { id: "15", color: "#8763ca" },
  { id: "47", color: "#757575" },
  { id: "16", color: "#b586e2" },
  { id: "48", color: "#a3a3a3" },
];
