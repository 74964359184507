<template>
  <div class="Tip">
    <v-tooltip
      open-delay="750ms"
      dark
      right
      nudge-right="40"
      nudge-top="40"
      class="wrap-text"
      max-width="800"
      color="blue darken-4"
    >
      <template v-slot:activator="{ on, attrs }">
        <!--         <v-container class="green">
 -->
        <v-icon v-bind="attrs" class="icon" v-on="on">
          mdi-information-variant
        </v-icon>
        <slot />
        <!--         </v-container>
 -->
      </template>
      <div>
        <h3 v-html="text"></h3>
      </div>
    </v-tooltip>
  </div>
</template>
<script>
import mixins from "@/components/mixins";
("Tip");
export default {
  mixins: [mixins],
  props: {
    text: {
      type: String,
      required: true,
    },
  },
};
</script>
<style scoped>
.icon {
  width: 0px;
  padding-left: 5px;
  padding-right: 5px;
}
</style>
