import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"ridesTable"}},[(_vm.rideLeader === 'all')?_c('div',[_c(VCard,{attrs:{"color":this.$headerColor,"flat":""}},[_c(VRow,{attrs:{"justify":"center"}},[_c(VCol,{attrs:{"cols":"2"}},[_c(VTextField,{attrs:{"label":"Search in rides","prepend-icon":"mdi-magnify","width":"40","single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1)],1):_vm._e(),_c(VCard,{staticClass:"view",class:_vm.rideLeader === 'all' ? 'view' : 'expanded-rides',attrs:{"color":this.$bgColor,"height":_vm.height}},[_c(VCard,{staticClass:"table-container",attrs:{"color":this.$bgColor}},[_c(VDataTable,{staticClass:"flex-table",class:this.$bgColor,attrs:{"disable-pagination":"","v-model":_vm.rideList,"l:height":"rideLeader === 'all' ? 650 : -1","multi-sort":"","fixed-header":"","hide-default-header":_vm.rideLeader === 'all' ? false : false,"hide-default-footer":"","dsdense":"","headers":_vm.headers,"items":_vm.rideList,"search":_vm.search},scopedSlots:_vm._u([{key:"item.edit",fn:function({ item }){return [_c(VBtn,{attrs:{"color":_vm.btnColor,"small":""},on:{"click":function($event){return _vm.teamUpEdit(item.id)}}},[_vm._v("Edit")])]}},{key:"item.displayStatus",fn:function({ item }){return [_c(VChip,{attrs:{"dark":"","small":"","color":_vm.getStatusColor(item['custom'])}},[_vm._v(" "+_vm._s(item.displayStatus)+" ")])]}},{key:"item.pace",fn:function({ item }){return [_c(VChip,{attrs:{"dark":"","small":"","color":_vm.displayPace(item)}},[_vm._v(" "+_vm._s(item.pace)+" ")])]}},{key:"item.rrule",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.formatRrule(item.rrule))+" ")]}}])},[_vm._v(" --> ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }