<template>
  <div id="NewYearReset">
    <v-card :color="this.$headerColor">
      <v-row justify="center">
        <h1>New Year Reset</h1>
      </v-row>
      <v-col>
        <v-row justify="center">
          <v-card-title>
            This will archive the rides from {{ prevYear }} and set all the
            rides for {{ currentYear }} to pending
          </v-card-title>
        </v-row>
      </v-col>
      <div v-if="prevResetDate === overrideFlag">
        <v-row justify="center">
          <v-card width="600" dark class="red lighten-1 text-center">
            <v-card-subtitle class="red lighten-1 white--text">
              WARNING:
              <br />
              The New Year Reset lockout has been disabled. Running the New Year
              Reset now will remove any status changes made for
              {{ currentYear }} and set all rides to Pending.
              <br />
              <br />
              DON'T DO THIS UNLESS YOU REALLY KNOW WHAT YOU'RE DOING!
            </v-card-subtitle>
          </v-card>
        </v-row>
      </div>
      <div v-if="prevResetDate.slice(0, 4) < currentYear.toString()">
        <v-card :height="prevResetDate === overrideFlag ? 63 : 185" light class="text-center"
          :color="this.$headerColor">
          <v-btn :color="this.$buttonColor" class="resetBtn" elevation="12" @click="resetNewYear()">
            Reset the Calendar for use in {{ currentYear }}
          </v-btn>
        </v-card>
      </div>
      <div v-else>
        <v-row justify="center">
          <v-card width="400" dark class="blue lighten3 text-center">
            <h2>
              The New Year Reset was run on
              <br />
              {{ prevResetDate.slice(5, 20) }}.
              <br />
              <br />
            </h2>
            <v-card-subtitle class="white--text">
              You should only reset the rides calendar once per year.
              <br />
              <br />
            </v-card-subtitle>
          </v-card>

          <v-card h width="400" dark class="red lighten-1">
            <v-card-subtitle class="white--text">
              If something is really messed up you can rerun the New Year Reset.
              Doing this will wipe out all ride confirmations made since
              {{ prevResetDate.slice(5, 20) }}.
              <br />
              Press the button below to continue.
              <br />
              <br />
              <v-row justify="center" class="yellow--text">
                THIS IS PROBABLY NOT SOMETHING YOU WANT TO DO.
                <v-btn color="red darken-1 yellow--snackbarText" dark class="resetBtn" @click="overrideLock()">
                  Override the reset Lockout
                  <br />
                </v-btn>
                <br />
              </v-row>
            </v-card-subtitle>
          </v-card>
        </v-row>
      </div>
      <v-snackbar v-model="snackbar" color="red" :timeout="timeout" top>
        {{ snackbarText }}
      </v-snackbar>
      <StatusTable height="70vh" />
    </v-card>
  </div>
</template>
<script>
import axios from "axios";
// import PopUp from "../components/PopUp.vue";
import StatusTable from "../components/StatusTable.vue";
import mixins from "../components/mixins";
// import ButtonTip from "../components/ButtonTip.vue";
import EventBus from "../event-bus.js";

export default {
  name: "NewYearReset",
  components: {
    StatusTable,
  },
  mixins: [mixins],
  props: {
    resetDate: {
      type: String,
      default: "false",
    },
  },

  data() {
    return {
      snackbarText: "",
      overRideMsg: "",
      timeout: -1,
      resetMsg: false,
      snackbar: false,
      prevYear: "",
      currentYear: "",
      prevResetDate: "",
      showRefresh: false,
      pend: false,
      summaryData: [],
      overrideFlag: "2020 Reset by Override",
      expanded: [],
      render: "no",
      singleExpand: false,
      rideLeader: "",
      calendar: this.$calendar,
      rideLeaderList: ["jay"],
      rideList: null,
      msg: "",
      x: 1,
      status: "",

      headers: [
        {
          Text: "Ride Leader",
          align: "start",
          value: "rideLeader",
          width: "60",
        },
      ],
    };
  },
  async created() {
    this.init();
  },
  methods: {
    overrideLock() {
      this.updateResetFlag("WRITE", "2020 Reset by Override");
    },

    async updateResetFlag(mode, newYearDate) {
      console.log(mode, newYearDate);
      var url =
        this.servers["python"] +
        "newYearCheck?filename=newYearCheck.msg&id=" +
        mode +
        "&msg=" +
        newYearDate;
      await axios({
        method: "GET",
        url: url,
      })
        .then((response) => {
          console.log(mode + " reset date " + response.data);
          this.prevResetDate = response.data;
        })
        .catch((error) => {
          this.handleError("Error setting New Year Lock", error);
          EventBus.$emit("wait", false);
        });
    },

    async resetNewYear() {
      this.render = "nos";

      this.snackbarText = "Resetting..";
      this.rideLeader = "";
      this.snackbar = true;

      // Current Year

      console.log("Resetting for the current year");
      year = this.getYear("current");
      resp = await this.getRides(
        "all",
        "all",
        "force",
        year + "-1-1",
        year + "-12-31"
      );
      await this.resetCalendar(resp, year);


      var year = "";
      var resp = [];
      console.log("Resetting for the pervious  year");
      year = this.getYear("previous");
      resp = await this.getRides(
        "all",
        "all",
        "force",
        year + "-1-1",
        year + "-12-31"
      );
      await this.resetCalendar(resp, year);

      console.log("Update the last reset date");
      year = this.getYear("current");
      resp = await this.getRides(
        "all",
        "all",
        "force",
        year + "-1-1",
        year + "-12-31"
      );
      var today = new Date();
      var setDate = year + " " + today.toDateString();
      await this.updateResetFlag("WRITE", setDate);

      await this.resetCalendar(resp, year);
      // Current Year



      /*            .then((results) => {
                 console.log(results);
               })            
            year = this.getYear("current");
            resp = this.getRides(
              "all",
              "all",
              "force",
              year + "-1-1",
              year + "-12-31"
            );
             */
      console.log("Reset Complete")

      EventBus.$emit("resetStatusTable", true);
      this.render = "yes";

      this.snackbar = false;
    },

    async resetCalendar(rides, year) {
      console.log(
        "**************** Reseting " + year);
      const statusID = this.$store.state.status.id;
      const pend = this.getStatusID("pend");
      var updates = [];
      this.statusName = this.$store.state.status.name;
      for (let j = 0; j < rides.length; ++j) {
        if (year === this.prevYear) {
          rides[j].redit = "single";
          rides[j].rrule = "";
          //          rides[j].ristart_dt = null;
          //          rides[j].rsstart_dt = null;
        } else {
          rides[j].redit = "future";
          rides[j].custom[statusID][0] = pend;
        }
        //        await this.updateRide(rides[j]);
        // }
        updates.push({
          method: "put",
          url: this.servers["calendar"] + "events/" + rides[j].id,
          data: rides[j],
          headers: this.$store.state.teamupHeaders,
        });
      }

      await Promise.all(updates.map((updates) => axios(updates)))
        .then((results) => {
          console.log(results.data);
        })
        .catch((error) => {
          this.handleError(
            "Error resetting calendar for " + year + "Error:",
            error,
            "URL: " + error.url
          );

          EventBus.$emit("wait", true);
        });
    },
    async init() {
      await this.getCalData()  // .then(() => {


      this.prevYear = this.getYear("previous");
      this.currentYear = this.getYear("current");
      if (this.resetDate === "true") {
        /* 
 
        await this.getRides(
          "all",
          "all",
          "force",
          this.currentYear + "-1-1",
          this.currentYear + "-12-31"
        );
 */
        this.updateResetFlag("WRITE", "2020 Reset by Override");
        //       this.$router.resetDate = [];
        //    EventBus.$emit("resetStatusTable", true);
        //      this.$router.push("/NewYearReset/false");
      } else {

        this.updateResetFlag("READ", "");
      }
      //      });
    },
  },
};
</script>
<style scoped>
.resetBtn {
  margin: 10px;
}
</style>
