<template></head>
  <div id="app">
    <v-app>
      <v-main>
        <div v-if="showMenu === true">
          <confirm ref="confirm" top></confirm>
          <!--           <v-app-bar fixed position="absolute" :color="this.$bgColor" dark height="8" width="100%">
          </v-app-bar>
        </div>
        <div v-else>
 --> <!--         <div v-if="getPWD() === 'ok'">
 -->
          <v-app-bar fixed position="absolute" :color="this.$menuColor" dark height="40" width="100%" class="appBar">
            <v-row justify="center" align="center">
              <v-col cols="1">
                <v-btn fixed x-small height="0" color="white--text" @click="drawer = !drawer">
                  Menu
                </v-btn>
                <v-app-bar-nav-icon @click="drawer = !drawer">
                </v-app-bar-nav-icon>
                <!--                   @mouseup="drawer = !drawer"
 -->
              </v-col>
              <v-col>
                <h4>Rides Chair Dashboard: {{ title }}</h4>
              </v-col>
            </v-row>
          </v-app-bar>

          <v-progress-linear :v-model="waitActive" :indeterminate="waitActive ? true : false" height="56" full-width
            :color="this.$waitColor" :background-color="this.$menuColor">
          </v-progress-linear>

          <v-overlay v-model="waitActive">
            <!-- 

            <v-img class="waitImage" src="https://test.ebcrides.org/images/bluffingtonRavineRec.gif"
              :v-model="waitActive">
            </v-img>
 -->
            <v-img class="waitImage" src="https://ebcrides.org/images/bikingInTheWoods.gif" :v-model="waitActive">


            </v-img>
            <v-row justify="center" align="center" class="wait">
              Please Wait...

            </v-row>
          </v-overlay>

          <v-navigation-drawer v-model="drawer" fixed temporary :color="this.$menuColor" dark>
            <v-list>
              <v-list-item @click="noCache('/RideStatus')">Ride Leader Status</v-list-item>
              <v-list-item @click="noCache('/List')">Ride List</v-list-item>
              <v-list-item @click="noCache('/Browser')">Image Browser</v-list-item>
              <v-list-item @click="noCache('/MsgEditor/newYear.msg/New Year Message/')">
                Edit eMail Messages
              </v-list-item>
              <v-list-item @click="noCache('/NewYearReset/False/')">
                New Year Reset
              </v-list-item>
              <v-list-item @click="noCache('/RideLeaders')">Email Address Book</v-list-item>
              <v-list-item @click="noCache('/RideReview/Joanne%20Davis/all')">
                Ride Leaders Review Sample
              </v-list-item>
              <v-list-item @click="showReminderLog()">
                Show Ride Reminder Log
              </v-list-item>
              <v-list-item @click=" noCache('/About')"> About
              </v-list-item>
              <v-list-item @click="logout()">Logout <br />
                &nbsp; (will clear saved password)</v-list-item>
            </v-list>
          </v-navigation-drawer>
        </div>

        <router-view>
        </router-view>

      </v-main>
    </v-app>
  </div>
  <!-- </v-app> -->
</div></template>

<script>
// const server = "http:/192.168.1.12/ebc.ebcrides.org/";

import EventBus from "./event-bus";
import confirm from "./components/Confirm";
import mixins from "./components/mixins";
import Vue from "vue";
import moment from "moment";
import router from "./router";
export default {
  name: "App",
  components: {
    confirm,
  },
  mixins: [mixins],
  data: () => ({
    title: "",
    drawer: false,
    //    showRideReview: false,
    auth: false,
    waitActive: true,
    showMenu: true,
  }),

  methods: {
    handleError(x, y, z) {
      alert(x + y + z)
    },
    showReminderLog() {
      var win = window.open("about:blank");
      var url = this.servers["html"] + "public/reminderLog";
      //  this.teamup = url;
      win.location = url;
    },
    getPWD() {
      var ret = sessionStorage.getItem("calendarPWD");
      return ret;
    },
    logout() {
      sessionStorage.setItem("calendar", "");
      localStorage.setItem("calendar", "");


      this.drawer = false;
      this.noCache("/");
    },
  },
  created() {
    process.env.VUE_APP_ROOT_API;
    /* 
        Vue.config.errorHandler = (err, vm, info) => {
          // vm: component in which error occured
          // info: Vue specific error information such as lifecycle hooks, events etc.
    
          //  x[2] = {
          //    agreeButtonText: "Done",
          //    oneButton: true,
          //  };
          alert("error: " + err + "\nInfo: " + info + "\n");
        };
    
     */    // TODO: Perform any custom logic or log to server


    EventBus.$on("appError", (x) => {
      if (x[2] == undefined) {
        x[2] = {
          agreeButtonText: "OK",
          oneButton: "hide",
          color: "red white--text",
        };
      }
      if (x[0] === "" || x[0] === null) {
        x[0] = "Error";
      }
      x[1] += "\n\n";
      //     x[1] += "\n--------------------------------------------------------------------------------------------";
      //     alert(x[0] + "\n\n" + x[1]);
      //      alert(x[0] + x[1]);
      //      this.$root.$confirm = 
      // this.processError(x).then((confirm) => {
      //   alert("Error: " + err + "n ")
      this.$refs.confirm.open(x[0], x[1], x[2]);
      //alert("Error: " + x[0] + " msg: " + x[1]);
    });

    EventBus.$on("title", (x) => {
      this.title = x;
    });
    EventBus.$on("showMenu", (x) => {
      this.showMenu = x;
      if (location.host.search("test") > -1)
        this.showMenu = true;
    });

    EventBus.$on("wait", (x) => {
      if (x === true) {
        this.waitActive = true;
      }
      else if (x === false) {
        this.waitActive = false;
      }
    });
  },
};
</script>

<style>
.waitImage {
  color: #0ff;
  opacity: 70%;
}

.wait {
  /*  color: #1a12b5; */
  color: #0ff;
  font-weight: bold;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-size: 33px;
  padding-top: 3px;
}

.appBar {
  padding: 10px;
  padding-bottom: 50px;
}
</style>

<style>
html * {
  font-size: 1 rem;
}

h1 {
  font-size: 1.5rem !important;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
}

h2 {
  font-size: 1.3rem !important;
}

h3 * {
  font-size: 1.1rem !important;
  /*  color: #000 !important;*/
}

h4 {
  font-size: 1.4rem !important;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
}

*.row-height td {
  height: 34px !important;
  font-size: 0.8rem !important;
}

button * {
  font-size: 0.6rem !important;
  font-weight: bold;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

.sub-heading {
  font-size: 11px;
}
</style>
