//import axios from "axios";
import Vue from "vue";
import Vuex from "vuex";
//import createPersistedState from "vuex-persistedstate";

//import EventBus from "@/event-bus";
Vue.use(Vuex);
export default new Vuex.Store({
  /*
  plugins: [
    createPersistedState({
      paths: ["teamupHeaders"],
    }),
  ],
  plugins: [createPersistedState()],
*/

  state: {
    servers: {},
    password: "",
    config: null,
    message: [],
    teamupHeaders: {},
    // teamupPassword: {},
    subCalendars: [],
    ridesList: [],
    rideLeaders: [],
    rideLeader: "",
    status: {
      id: "",
      name: "",
      choices: [],
    },
    // depricated
    customDefs: [],
    statusFieldID: "",
    statusFieldName: "",
    fieldDefs: [],
    statusValues: [],
  },
  mutations: {
    //    setHeader: (state) => (state.tHeaders.teamupPassword = "abc"),
    //    setDefs: (state, definitions) => (state.definitions = definitions),
    //   getCalData: (state, cfg) => (state.cfg = cfg),
    //    setRide: (state, ride, index) => (state.rides[index] = ride),
  },
  actions: {
    /*
    async updateRide2(ride, updates) {
      var newRide = {
        all_day: ride.all_day,
        creation_dt: ride.creation_dt,
        custom: ride.custom,
        id: ride.id,
        series_id: ride.series_id,
        start_dt: ride.start_dt,
        end_dt: ride.end_dt,
        subcalendar_id: ride.subcalendar_id,
        version: ride.version,
        subcalendar_ids: ride.subcalendar_ids,
        rrule: ride.rrule,
        title: ride.title,
        who: ride.who,
        location: ride.location,
        notes: ride.notes,
        dialog: ride.dialog,
        readonly: ride.readonly,
        ristart_dt: ride.ristart_dt,
        rsstart_dt: ride.rsstart_dt,
        update_dt: ride.update_dt,
        delete_dt: ride.delete_dt,
        tz: ride.tz,
        attachments: ride.attachments,
        remote_id: ride.remote_id,
      };

      var APIkey =
        "ca3fec0bc53190c90863e0f41579e27cbc98a57a97c909455df7db816f4ae4bd";

      //var url =
      // this.servers["calendar'] + "events/" + ride.id;
      /*      var newRide2 = [];
      await axios({
        method: "GET",
        url: url,
        data: ride.id,
        //        data: newData,
        headers: {
          "Teamup-Token": APIkey,
          "Content-type": "application/json; charset=UTF-8",
        },
      }).then((results) => {
        console.log(results);

      var objKeys = Object.keys(updates);

      objKeys.forEach((key) => {
        newRide[key] = updates[key];
        console.log(key + ": " + this.pp(updates[key]));
      });

      APIkey =
        "ca3fec0bc53190c90863e0f41579e27cbc98a57a97c909455df7db816f4ae4bd";

      var url = this.servers["calendar'] + "events/" + newRide.id;

      //  await axios({
      axios({
        method: "PUT",
        url: url,
        data: newRide,
        headers: {
          "Teamup-Token": APIkey,
          "Content-type": "application/json; charset=UTF-8",
        },
      })
        .then((response) => {
          var val = response.data.event;
          console.log(val);
          //          context.commit("setRide", val, val.id);
        })
        .catch((error) => {
          console.log("Error updating status Message" + this.pp(error));
          EventBus.$emit("wait",  false);
        });
    },
  */
  },

  modules: {},
});
