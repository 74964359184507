<template>
  <div id="Login">
    <v-img src="https://ebcrides.org/images/lake%20bluff.jpg" contain>
      <v-container float fill-height>
        <v-col>
          <v-layout align-center justify-center>
            <!--             <v-flex xs12 sm8 md4>
 -->
            <v-snackbar v-model="snackbar" color="red text--white" top timeout="3000">
              <div class="text-center">
                {{ snackbarText }}
              </div>
            </v-snackbar>
            <v-card class="elevation-12" color="yellow lighten-5">
              <v-toolbar dark color="green darken-4">
                <v-toolbar-title class="loginbg">
                  <v-img width="200" src="https://ebcrides.org/userfiles/images/miscImages/ebcbanner.jpg"></v-img>
                  Rides Calendar Admin
                </v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                {{ servers["python"] }}
                <v-form ref="form" @keyup.native.enter="setPassword(login, password)">
                  <v-text-field prepend-icon="mdi-account" name="login" label="User Name" type="text"></v-text-field>
                  <v-text-field v-model="password" prepend-icon="mdi-lock" name="password" label="Password"
                    :type="showPassword ? 'text' : 'password'"></v-text-field>
                </v-form>
                <!--                 <span class="error help-block"></span>
                <div class="text-danger" v-if="error">
                  <p>Incorrect password.</p>
                </div>
 -->
                <v-checkbox v-model="showPassword" label="Show Password"
                  @click="showPassword = showPassword"></v-checkbox>
                <v-checkbox v-model="savePWD" label="Remember Password" @click="savePWD = savePWD"></v-checkbox>
              </v-card-text>
              <v-spacer></v-spacer>
              <v-col align="center">
                <v-btn dark color="green darken-4" @click="setPassword(login, password)">
                  Login
                </v-btn>
                <!--                         :label="`Checked: ${checked}`"
 -->
              </v-col>
              <v-card-actions></v-card-actions>
            </v-card>

            <!--        </v-flex>
      -->
          </v-layout>
        </v-col>
      </v-container>
    </v-img>
  </div>
</template>

<script>
import "vuetify/dist/vuetify.min.css";
//import CryptoJS from "crypto-js";
import mixins from "@/components/mixins";
import EventBus from "@/event-bus.js";
export default {
  name: "Login",
  /*   props: {
    sourceString,
  },
 */
  mixins: [mixins],
  data() {
    return {
      login: "",
      password: "",
      showPassword: false,
      snackbarText: "Login Failed",
      snackbar: false,
      savePWD: false,
      error: null,
    };
  },
  //   var ret = await this.checkPassword();
  //   if (ret === "ok") {
  //     return this.$router.push({ path: "///RideStatus" });
  methods: {
    async setPassword(login, password) {
      //   if (login !== "") return -1;
      // console.log(login + password);
      var ret = await this.checkPassword(password, this.savePWD ? "plainText" : false);
      // console.log("check passord: ", ret);
      if (ret === "ok") {
        EventBus.$emit("placeHolder", true); //import axios from "axios";    /*     var password = localStorage.getItem("calendar");
        this.noCache("/RideStatus");
      }
      else if (ret === "error") {
        $his.handleError("An error occurried while trying to validate the password. Try reloading.")
        this.$refs.form.reset();
        this.snackbar = true;
      }
      else if (ret === "login") {
        this.$refs.form.reset();
        this.snackbarText = "Invalid Password or User Name.",
          this.snackbar = true;

        //      await this.sleep(2000).then(() => {
        //        this.$router.go(0);
        //    });
      }
    },
  },
  mounted() {
    //console.log("login");
    EventBus.$emit("wait", false);
    EventBus.$emit("showMenu", false);
    /*     var password = localStorage.getItem("calendar");
        if (password !== null && password !== "") {
          var ret = await this.checkPassword(password);
          if (ret === "ok") {
            this.$router.push({ path: "/RideStatus" });
          }
   */
  },

};
</script>

<style scoped>
.loginbg {
  margin-top: 20pt;
  margin-bottom: 15pt;
}
</style>
